import { useLoaderData } from "react-router-dom";
import {Grid} from "@mui/material";
import {getAllPinsMap} from "../data/pin-definitions";
import AllPagesPDFViewer from "../pdf/all-pages";

export async function loader({ params }) {
    return getAllPinsMap()[params.pinSlug];
}

export default function Pin() {
    const pin = useLoaderData();

    return (
        <div className="App" id="sacred-texts">
            <header className="App-header">
                <a href="/pins">Back</a>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <Grid className="Slider-grid" container direction="column">
                    {
                        pin.text == null ? "" :
                            <Grid item>
                                <blockquote className="display-line-break" dangerouslySetInnerHTML={{ __html: pin.text }}/>
                            </Grid>
                    }
                    {
                        pin.link == null ? "" :
                            <Grid item>
                                <a href={pin.link}>{pin.link}</a>
                            </Grid>
                    }
                    {
                        pin.image == null ? "" :
                            <Grid item>
                                <a href={pin.image} target="_blank" rel="noreferrer">
                                    <img className="pin-img" alt={pin.author} src={pin.image}/>
                                </a>
                            </Grid>}
                    {
                        pin.audio == null ? "" :
                            <Grid item>
                                <audio controls>
                                    <source src={pin.audio} type="audio/mpeg"/>
                                    Your browser does not support the audio element.
                                </audio>
                            </Grid>}
                    {
                        pin.video == null ? "" :
                            <Grid item>
                                <video width="300" height="300" controls>
                                    <source src={pin.video} type="video/mp4"/>
                                    Your browser does not support the video tag.
                                </video>
                            </Grid>
                    }
                    {
                        pin.caption == null ? "" :
                            <Grid item>
                                <p className="display-line-break" dangerouslySetInnerHTML={{ __html: pin.caption }} />
                            </Grid>
                    }
                    <Grid item><p>~{pin.author} (<a href={pin.discordLink}>Source</a>)</p></Grid>
                    {
                        pin.pdf == null ? "" :
                            <Grid item>
                                <AllPagesPDFViewer pdf={pin.pdf}/>
                            </Grid>
                    }
                </Grid>
            </header>
        </div>
    )
}
