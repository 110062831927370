import {getAllSacredTextsSlugs, all_sacred_texts} from "../data/sacred-texts-definitions";
import LinkIcon from "@mui/icons-material/Link";

export default function SacredTextsList() {
  return (
    <div className="App" id="sacred-texts">
        <header className="App-header">
            {getAllSacredTextsSlugs().map((sacred_text_slug, i) => {
                const sacred_text_object = all_sacred_texts[sacred_text_slug];
                if(sacred_text_object.url){
                    return <span>
                        <a className="App-link" href={sacred_text_object.url} key={i} target="_blank" rel="noopener noreferrer">{sacred_text_object.title}</a>
                        &nbsp;
                        <LinkIcon color="primary"/>
                    </span>;
                }else{
                    return <a className="App-link" href={`/sacred-texts/${sacred_text_slug}`} key={i} >{sacred_text_object.title}</a>;
                }
            })}
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <a href="/">Back</a>
        </header>
    </div>
  );
}
