import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import ErrorPage from "./error-page";
import SacredText, {loader as sacredTextLoader} from "./routes/sacred-text";
import SacredTexts from "./routes/sacred-texts-list";
import PinsList from "./routes/pins-list";
import Pin, {loader as pinLoader} from "./routes/pin";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorPage />,
    },
    {
        path: "pins",
        element: <PinsList />,
    },
    {
        path: "pins/:pinSlug",
        element: <Pin />,
        loader: pinLoader,
    },
    {
        path: "sacred-texts",
        element: <SacredTexts />,
    },
    {
        path: "sacred-texts/:sacredTextSlug",
        element: <SacredText />,
        loader: sacredTextLoader,
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
