import memoize from 'fast-memoize';
import slugify from 'slugify';

export const all_sacred_texts = {
    vixenhood: {
        title: "Vixenhood",
        image: "/static/sacred-texts/vixenhood.png",
        audio: null,
        audioTranscription: null,
        url: null,
    },
    freckles: {
        title: "Freckles",
        image: "/static/sacred-texts/freckles.png",
        audio: null,
        audioTranscription: null,
        url: null,
    },
    box: {
        title: "Box",
        image: "/static/sacred-texts/box.png",
        audio: null,
        audioTranscription: null,
        url: null,
    },
    plush: {
        title: "Plush",
        image: "/static/sacred-texts/plush.png",
        audio: null,
        audioTranscription: null,
        url: null,
    },
    lick: {
        title: "Lick",
        image: "/static/sacred-texts/lick.png",
        audio: null,
        audioTranscription: null,
        url: null,
    },
    kits: {
        title: "Kits",
        image: null,
        audio: "/static/sacred-texts/kits.mp3",
        audioTranscription: "Beloved, come to bed. My body aches for your touch, and the concerns of the 'morrow can wait. The spring is here and with it comes the feverish yearning for motherhood - a yearning I will not deny. Please Beloved, lay with me once more and sow the seeds of a beautiful litter of kits.",
        url: null,
    },
    snug: {
        title: "Snug",
        image: "/static/sacred-texts/snug-v5.png",
        audio: null,
        audioTranscription: null,
        url: null,
    },
    book: {
        title: "Book",
        image: "/static/sacred-texts/book.png",
        audio: null,
        audioTranscription: null,
        url: null,
    },
    admire: {
        title: "Admire",
        image: null,
        audio: null,
        audioTranscription: null,
        url: "https://forum.questionablequesting.com/threads/borne-of-caution-fox-box-snippets-pokemon.22309/page-2#post-6859107",
    },
    joyous: {
        title: "Joyous (1/4)",
        image: "/static/sacred-texts/joyous.png",
        audio: null,
        audioTranscription: null,
        url: null,
    },
    macho: {
        title: "Macho (2/4)",
        image: "/static/sacred-texts/macho.png",
        audio: null,
        audioTranscription: null,
        url: null,
    },
    ember: {
        title: "Ember (3/4)",
        image: "/static/sacred-texts/ember.png",
        audio: null,
        audioTranscription: null,
        url: null,
    },
    heaven: {
        title: "Heaven (4/4)",
        image: "/static/sacred-texts/heaven.png",
        audio: null,
        audioTranscription: null,
        url: null,
    },
    heat: {
        title: "Heat (1/2)",
        image: "/static/sacred-texts/heat.png",
        audio: null,
        audioTranscription: null,
        url: null,
    },
    relief: {
        title: "Relief (2/2)",
        image: "/static/sacred-texts/relief.png",
        audio: null,
        audioTranscription: null,
        url: null,
    },
    firsthand: {
        title: "Firsthand (1/3)",
        image: "/static/sacred-texts/firsthand.png",
        audio: null,
        audioTranscription: null,
        url: null,
    },
    wants: {
        title: "Wants (2/3)",
        image: "/static/sacred-texts/wants.png",
        audio: null,
        audioTranscription: null,
        url: null,
    },
    composure: {
        title: "Composure (3/3)",
        image: "/static/sacred-texts/composure.png",
        audio: null,
        audioTranscription: null,
        url: null,
    },
    questions: {
        title: "Questions (1/3)",
        image: "/static/sacred-texts/questions.png",
        audio: null,
        audioTranscription: null,
        url: null,
    },
    reminisce: {
        title: "Reminisce (2/3)",
        image: "/static/sacred-texts/reminisce.png",
        audio: null,
        audioTranscription: null,
        url: null,
    },
    rebuild: {
        title: "Rebuild (3/3)",
        image: "/static/sacred-texts/rebuild.png",
        audio: null,
        audioTranscription: null,
        url: null,
    },
    poachers: {
        title: "Poachers (1/2)",
        image: "/static/sacred-texts/poachers.png",
        audio: null,
        audioTranscription: null,
        url: null,
    },
    burdens: {
        title: "Burdens (2/2)",
        image: "/static/sacred-texts/burdens.png",
        audio: null,
        audioTranscription: null,
        url: null,
    },
    distraction: {
        title: "Distraction (NSFW)",
        image: null,
        audio: null,
        audioTranscription: null,
        url: "https://forum.questionablequesting.com/threads/borne-of-caution-fox-box-snippets-pokemon.22309/page-4?post=8787711#post-8787711",
    },
    web: {
        title: "Banned (NSFW)",
        image: null,
        audio: null,
        audioTranscription: null,
        url: "https://forum.questionablequesting.com/threads/borne-of-caution-fox-box-snippets-pokemon.22309/page-4#post-8867003",
    },
};

const _getAllSacredTextsSlugs = function() {
    return Object.keys(all_sacred_texts).map((item) => {
        return slugify(item, {lower: true});
    })
}

export const getAllSacredTextsSlugs = memoize(_getAllSacredTextsSlugs)
