import './App.css';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';

function App() {
  return (
    <div className="App">
      <header className="App-header">
          <a
              className="App-link"
              href="/pins"
              // target="_blank"
              rel="noopener noreferrer"
          >Fox Box Pins</a>
          <a
              className="App-link"
              href="/sacred-texts"
              // target="_blank"
              rel="noopener noreferrer"
          >Sacred Texts</a>
      </header>
    </div>
  );
}

export default App;
