import memoize from "fast-memoize";
import hash from "hash.js"

export const all_pins = [
    {
        title: "The Beginning",
        author: "Fuggmann",
        text: "#fox-box is a polite way to say #dog-fuckers",
        link: null,
        image: null,
        audio: null,
        video: null,
        pdf: null,
        caption: null,
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1025620075878821928",
    },
    {
        title: "Kits",
        author: "Bobbles",
        text: ">\"What do you think of him, miss Ninetales?\" Octillery asks\n" +
            ">\"I'm going to have kits one day, and I'll be damned if they aren't going to be his.\"\n" +
            ">The octopus's eyes widen, almost bugging out before he composes himself.",
        link: null,
        image: null,
        audio: null,
        video: null,
        pdf: null,
        caption: null,
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1025627653300027423",
    },
    {
        title: "Do Not Lewd",
        author: "ShaRose",
        text: null,
        link: null,
        image: "/static/pins/nolewd.png",
        audio: null,
        video: null,
        pdf: null,
        caption: "also belongs in here cuz yall need arceus",
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1026720245529972736",
    },
    {
        title: "Ara Ara",
        author: "Bobbles",
        text: null,
        link: null,
        image: "/static/pins/tittertales-full-size.png",
        audio: null,
        video: null,
        pdf: null,
        caption: "You're welcome. Shoutout to the wonderful artist Pabbley, who made the image 😄",
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1026818912840581190",
    },
    {
        title: "No Way, Love",
        author: "Bobbles",
        text: null,
        link: null,
        image: "/static/pins/9t-lee-shoulder.png",
        audio: null,
        video: null,
        pdf: null,
        caption: null,
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1027358783321669652",
    },
    {
        title: "Insomnia",
        author: "Bobbles",
        text: null,
        link: null,
        image: "/static/pins/9t-lee-bed-think.png",
        audio: null,
        video: null,
        pdf: null,
        caption: null,
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1027359236855955476",
    },
    {
        title: "Did You Know?",
        author: "DJ Firefox",
        text: "Did you Know? That in terms of male human and female Pokémon breeding, Ninetales is the most compatible Pokémon for humans? Not only are they in the field egg group, which is mostly comprised of mammals, Ninetales are an average of 3”07’ tall and 43.9 pounds, this means they’re large enough to be able handle human dicks, and with their impressive Base Stats for Special Defense and Speed, you can go all night with one. Due to their supreme fluffiness and prehensile tails I’m sure you could get the most sensual handjob of all time. They can also learn the moves Attract, Baby-Doll Eyes, Captivate, Charm, and Tail Whip so it’d be incredibly easy for one to get you in the mood.  Get cold during the act?  No problem with Drought.  You won’t even need to wear socks.",
        link: null,
        image: null,
        audio: null,
        video: null,
        pdf: null,
        caption: "I...am sorry",
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1037931122086137947",
    },
    {
        title: "Foxes, Foxes Everywhere",
        author: "Bluey",
        text: null,
        link: null,
        image: null,
        audio: null,
        video: "/static/pins/foxes-lots-of-foxes.mp4",
        pdf: null,
        caption: null,
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1044824816663928943",
    },
    {
        title: "Puddle",
        author: "emeraldleafeon",
        text: null,
        link: null,
        image: "/static/pins/puddle-bird.jpg",
        audio: null,
        video: null,
        pdf: null,
        caption: null,
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1046942051188613130",
    },
    {
        title: "Beesona",
        author: "Stostri",
        text: null,
        link: null,
        image: "/static/pins/fugg-fursona.png",
        audio: null,
        video: null,
        pdf: null,
        caption: "Hey guys I think I found Fugg's fursona",
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1046972555539517461",
    },
    {
        title: "Proud",
        author: "emeraldleafeon",
        text: null,
        link: null,
        image: "/static/pins/proudtales.png",
        audio: null,
        video: null,
        pdf: null,
        caption: null,
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1046984825539670029",
    },
    {
        title: "Sunset",
        author: "emeraldleafeon",
        text: null,
        link: null,
        image: "/static/pins/9t-lee-sunset.jpg",
        audio: null,
        video: null,
        pdf: null,
        caption: null,
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1047712991065419867",
    },
    {
        title: "Boop",
        author: "Hetzer CoÑ",
        text: null,
        link: null,
        image: "/static/pins/9t-boop.gif",
        audio: null,
        video: null,
        pdf: null,
        caption: "fucking finally",
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1049408633387110410",
    },
    {
        title: "Box 1",
        author: "Fear the Psychic Cat",
        text: null,
        link: null,
        image: "/static/pins/9t-box.jpg",
        audio: null,
        video: null,
        pdf: null,
        caption: "Fox box.",
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1050529928883470346",
    },
    {
        title: "Carrier",
        author: "LifeAndLemmons 🍋",
        text: null,
        link: null,
        image: "/static/pins/9t-box-2.png",
        audio: null,
        video: null,
        pdf: null,
        caption: "we need more fox boxes",
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1050532805949534292",
    },
    {
        title: "Cool Whip",
        author: "Bobbles",
        text: null,
        link: null,
        image: "/static/pins/WhydidImakethis.png",
        audio: null,
        video: null,
        pdf: null,
        caption: null,
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1051376484553601055",
    },
    {
        title: "Cheese",
        author: "ShaRose",
        text: null,
        link: null,
        image: "/static/pins/cheesetales.png",
        audio: null,
        video: null,
        pdf: null,
        caption: null,
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1051376128658518036",
    },
    {
        title: "However",
        author: "egoo",
        text: null,
        link: null,
        image: "/static/pins/LeeXnine_shitpost.png",
        audio: null,
        video: null,
        pdf: null,
        caption: "Thanks for all the giggles. Now, time for me to contribute as well.",
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1053386323160989887",
    },
    {
        title: "Bee <3 Gato",
        author: "ShaRose",
        text: null,
        link: null,
        image: "/static/pins/bee-heart-sprigato.gif",
        audio: null,
        video: null,
        pdf: null,
        caption: null,
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1053808422535049278",
    },
    {
        title: "Stuck-in-a-Box",
        author: "egoo",
        text: null,
        link: null,
        image: "/static/pins/fox-box-3.png",
        audio: null,
        video: null,
        pdf: null,
        caption: "Behold! The real fox-box!",
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1053882666560270396",
    },
    {
        title: "No Way, Love <3 Ara Ara",
        author: "ShaRose",
        text: null,
        link: null,
        image: "/static/pins/lee-heart-9t.gif",
        audio: null,
        video: null,
        pdf: null,
        caption: "now for a tittertales version",
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1053809691601408040",
    },
    {
        title: "Jaded Joy",
        author: "Stostri",
        text: null,
        link: null,
        image: "/static/pins/suffering_joy.png",
        audio: null,
        video: null,
        pdf: null,
        caption: "I made a thing",
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1053904954278035496",
    },
    {
        title: "Flammenwerfer",
        author: "egoo",
        text: null,
        link: null,
        image: "/static/pins/flammenwerfer.png",
        audio: null,
        video: null,
        pdf: null,
        caption: null,
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1055487523767455955",
    },
    {
        title: "Proposal",
        author: "emeraldleafeon",
        text: null,
        link: null,
        image: "/static/pins/9t-proposal.png",
        audio: null,
        video: null,
        pdf: null,
        caption: null,
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1055527914298421388",
    },
    {
        title: "Gigachad",
        author: "Hetzer CoÑ",
        text: null,
        link: null,
        image: null,
        audio: null,
        video: "/static/pins/talesmenace.mp4",
        pdf: null,
        caption: null,
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1056348281279619164",
    },
    {
        title: "On This Planet",
        author: "egoo",
        text: null,
        link: null,
        image: "/static/pins/on-this-planet.png",
        audio: null,
        video: null,
        pdf: null,
        caption: "In honour of the Sacred Texts being collated together",
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1057346483462094870",
    },
    {
        title: "Octodad",
        author: "emeraldleafeon",
        text: null,
        link: null,
        image: "/static/pins/octillerydad.png",
        audio: null,
        video: null,
        pdf: null,
        caption: "BoC2: Octillery gets a fucking job",
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1057523687911346237",
    },
    {
        title: "BoC Team Music",
        author: "Fuggmann",
        text: "Ninetales - https://www.youtube.com/watch?v=-BsAl6HVZ-Y\n" +
            "Grovyle - https://www.youtube.com/watch?v=GVnp18OEqq8\n" +
            "Corvisquire - https://www.youtube.com/watch?v=t6KFfYdNPh8\n" +
            "Octillery - https://www.youtube.com/watch?v=TU-MYe0SL9Q\n" +
            "Shinx - https://www.youtube.com/watch?v=3bBpiHJm3t0 I guess",
        link: null,
        image: null,
        audio: null,
        video: null,
        pdf: null,
        caption: null,
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1059313200656564235",
    },
    {
        title: "Fox Box 2023",
        author: "Hetzer CoÑ",
        text: null,
        link: null,
        image: "/static/pins/foxbox-2023.png",
        audio: null,
        video: null,
        pdf: null,
        caption: ">T9 learns politics and uses Lee as her puppet\n" +
            "An octopus has only 8 tentacles, which is one too few.",
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1059448054169743462",
    },
    {
        title: "Off Topic",
        author: "Alienspacekappa",
        text: null,
        link: null,
        image: "/static/pins/offtopic_v2.jpg",
        audio: null,
        video: null,
        pdf: null,
        caption: null,
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1060378228331651072",
    },
    {
        title: "Sad",
        author: "egoo",
        text: null,
        link: null,
        image: "/static/pins/egoo_got_a_pin.png",
        audio: null,
        video: null,
        pdf: null,
        caption: "♂️ My fellow♂️ fox-box ♂️ brothers ♂️. I present to you:",
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1061053944874422313",
    },
    {
        title: "Did You Know? (Audio)",
        author: "Dingus",
        text: null,
        link: null,
        image: null,
        audio: "/static/pins/synthesized_audio_8.mp3",
        video: null,
        pdf: null,
        caption: null,
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1077032007403589723",
    },
    {
        title: "Temporary",
        author: "DJ Firefox",
        text: null,
        link: null,
        image: "/static/pins/dont-look.jpg",
        audio: null,
        video: null,
        pdf: null,
        caption: null,
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1079124446356447284",
    },
    {
        title: "Got Milk?",
        author: "Fuggmann",
        text: "I awaken with a fever so have my intrusive thoughts.\n" +
            "\n" +
            ">Eevee\n" +
            "Standard mammal milk, though highly influenced by what the Eevee eats. You can practically taste the quality of her last meal, be it good or bad.\n" +
            "\n" +
            ">Flareon\n" +
            "Seemingly perpetually warm with a slight kick, as if spiked with alcohol. Wards off the cold by raising core body temp when consumed. Has the side effect of burning out diseases.\n" +
            "\n" +
            ">Jolteon\n" +
            "Produced in only small amounts, but densely packed with nutrients. Easily digested and has a tingling, invigorating zing. Slight metallic flavor might be off putting to some. \n" +
            "\n" +
            ">Vaporeon\n" +
            "Thin and watery. More akin to pure mineral water if not for the calcium and hormones within. Flushes toxins and impurities from the body, and still used as a medicine by coastal natives.\n" +
            "\n" +
            ">Umbreon\n" +
            "Thick with a slight acidic tang going down. Not a flavor enjoyed by most, but a superb immune system booster that promotes deep, restful sleep.\n" +
            "\n" +
            ">Espeon\n" +
            "Smooth texture and slightly luminescent in low light. Provides a slow-burn energy release and mental clarity. Best consumed at the start of the day.\n" +
            "\n" +
            ">Leafeon\n" +
            "Floral scent and taste along with a slight yellow tinge, as if mixed with honey. Remarkably sweet and naturally antibiotic with a long shelf life, but spoils quickly if processed. A good cure for an upset stomach.\n" +
            "\n" +
            ">Glaceon\n" +
            "Perpetually chilled and refreshing, if somewhat thin. Can be frozen and thawed repeatedly without harming the quality, perfect for younglings with a slow metabolism or needing something numbing to drink amid teething.\n" +
            "\n" +
            ">Sylveon\n" +
            "Thick, sweet, and packed with nutrients beyond milks natural saturation point, making Sylveon a coveted wet nurse among breeders. Very easy to digest and settles even the most sensitive stomach. Drinkers remark the flavor is \"off\" if they aren't acquainted with the Sylveon in question.\n" +
            "\n" +
            "/Autism\n" +
            "Time to quaff nyquil and return to bed",
        link: null,
        image: null,
        audio: null,
        video: null,
        pdf: null,
        caption: null,
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1079358825729118248",
    },
    {
        title: "Eight",
        author: "egoo",
        text: null,
        link: null,
        image: "/static/pins/9t-has-eight.png",
        audio: null,
        video: null,
        pdf: null,
        caption: "Not my best work but attempt has been made",
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1079462094228946944",
    },
    {
        title: "Fox Borne",
        author: "DJ Firefox",
        text: null,
        link: "https://www.royalroad.com/fiction/62778/fox-borne",
        image: "/static/pins/fox-borne-cover.jpg",
        audio: null,
        video: null,
        pdf: null,
        caption: null,
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1080279808770048010",
    },
    {
        title: "Box(ing)",
        author: "WholeWheatBagels",
        text: null,
        link: null,
        image: "/static/pins/9t-boxing.png",
        audio: null,
        video: null,
        pdf: null,
        caption: "gimp is being stupid welcome to google drawings hell",
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1080363723757203618",
    },
    {
        title: "Forgothrax's Snippets",
        author: "Forgothrax",
        text: "Forgothrax's Lee/9T Snippets",
        link: null,
        image: null,
        audio: null,
        video: null,
        pdf: null,
        caption: "[Moxie note: See source for up to date snippet links.]",
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1081277736682803300",
    },
    {
        title: "Welcome",
        author: "Ash Kitsune (CoN)",
        text: "ALCOHOL WEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE @MeowscaradaZXC|Schizo CatFucker @Moxie @Bobbles @2ScoopsPlz and I won't @ the bee because I'm  not that drunk but also @egoo",
        link: null,
        image: null,
        audio: "/static/pins/Welcome_to_the_Foxbox_w_Music.ogg",
        video: null,
        pdf: null,
        caption: null,
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1082909900834885705",
    },
    {
        title: "Bonked",
        author: "DJ Firefox",
        text: null,
        link: null,
        image: "/static/pins/bonked.png",
        audio: null,
        video: null,
        pdf: null,
        caption: null,
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1090035097933197413",
    },
    {
        title: "Liberation",
        author: "Alienspacekappa",
        text: "since someone asked, pin to celebrate this day of <s>tomfoolery</s> momentous occasion",
        link: null,
        image: "/static/pins/Fox_Box_Liberation.png",
        audio: null,
        video: null,
        pdf: null,
        caption: null,
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1091839669198667908",
    },
    {
        title: "Do Not Trust",
        author: "Fuggmann",
        text: "I found a sign generator",
        link: null,
        image: "/static/pins/Trust_Sign.png",
        audio: null,
        video: null,
        pdf: null,
        caption: null,
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1093584657066512485",
    },
    {
        title: "Box 2",
        author: "emeraldleafeon",
        text: null,
        link: null,
        image: "/static/pins/9t-box-3.png",
        audio: null,
        video: null,
        pdf: null,
        caption: "“Love, why are you in a box?”\n" +
            "\n" +
            "“Lee, help! Some people came along and stuck me in this and said I couldn’t get out until we got together!”\n" +
            "\n" +
            "“And you’re still in there, why?”\n" +
            "\n" +
            "“Well, I don’t want to disappoint them.”",
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1093611684817534988",
    },
    {
        title: "Mom",
        author: "DJ Firefox",
        text: "The neighborhood kids were a handful. Always trying to sneak a peek into the windows of the lab. They stood on tiptoe, on boxes, even on each other's shoulders in an attempt to see what the Professor was working on.  If it wasn't for the blackout curtains and noise cancelling headphones the good Professor would have gone crazy by now.\n" +
            "\n" +
            "The children whispered in loud voices in wonder and their sticky hands left smudges on the glass. After a few months of biweekly window washing, the Lab had simply given up and left the handprints. All it did was further obscure the children's vision anyhow.\n" +
            "\n" +
            "The Professor had taken several additional precautions with his latest experiments, keeping the shades drawn day or night. Bars were placed in the window sills and additional patrols kept watch.\n" +
            "\n" +
            "The device was similar to a Pokedex, but far less colorful and far too tiny to be a new prototype.  It was currently laying in pieces on the workbench with several cameras and scopes oriented at its nearly microscopic parts.\n" +
            "\n" +
            "\"Alright Zed...let's try this again.\"\n" +
            "\n" +
            "A bulbous pink and blue creature hovered in midair, yet its appendages were still and no air drafted from its floating body. If someone had tried to touch it, they would have been surprised to have their hand simply pass through.  A series of beeps, crackles, and rings echoed from a speaker unit on the tabletop and the yellow eyes of the holographic Pokemon lit up, green characters rolled down its irises in a waterfall of machine code. \n" +
            "\n" +
            "\"Proceeding with data extraction test 88.9.\"\n" +
            "\n" +
            "To the Professor, the sounds that the Pokemon made were familiar, but he couldn't quite place exactly how.  Considering all he had to do now was wait...he rummaged through his memories for the source.\n" +
            "\n" +
            "He snapped his fingers as it came to him.  A dial-up modem!\n" +
            "\n" +
            "\"Professor, please refrain from acoustic disturbances. This task is most arduous.\" The voice was heavily synthetic with the monotones that came from a cheap voice module.\n" +
            "\n" +
            "\"Sorry, Zed.  Please continue.\"\n" +
            "\n" +
            "Another 20 minutes passed, and the Professor had begun to nod off in his desk chair when Zed announced that the test was completed.\n" +
            "\n" +
            "\"Congratulations Professor. We have successfully interpreted several files.\"\n" +
            "\n" +
            "The Professor leapt out of his chair, tripping over the armrest as he scrambled to the monitor on the workbench. \n" +
            "\n" +
            "\"Show me what we got, Zed.\"\n" +
            "\n" +
            "Several black windows streamed across the screen for a moment before leaving behind a single window containing three files.\n" +
            "\n" +
            "The Professor's face fell, and if you had looked closely enough you would have seen the beginnings of tears.\n" +
            "\n" +
            "\"Th-three?\" His voice broke. \"After all that, we only got three?\"\n" +
            "\n" +
            "\"Most of the contents were an amalgamation of random strings.  It was determined that these files were unrecoverable.\"\n" +
            "\n" +
            "The Professor's eyes shone in the light of the monitor, watery streaks trailing down his face. Hesitantly at first, his fingers caressed the mouse.\n" +
            "\n" +
            "\"No matter.  Better than the previous hundred attempts.\"\n" +
            "\n" +
            "His shoulders hunched as the man steeled himself.  Dragging the cursor to the first file in the sequence, he clicked on it.\n" +
            "\n" +
            "The visage of a smiling woman greeted him. Her greying hair waved over her eyes and a young man stood stiffly by, his face in a frozen smile.\n" +
            "\n" +
            "The Professor's strength failed as his legs gave way from under him.\n" +
            "\n" +
            "\"Mom.  So that's what your face was like.\"\n" +
            "\n" +
            "Professor Henson Wept.",
        link: null,
        image: null,
        audio: null,
        video: null,
        pdf: null,
        caption: null,
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1093755492490289162",
    },
    {
        title: "Bonk Fire",
        author: "emeraldleafeon",
        text: null,
        link: null,
        image: "/static/pins/bonk-fire.jpg",
        audio: null,
        video: null,
        pdf: null,
        caption: "You’ve all heard of Fox Fire, now get ready for Bonk Fire!",
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1093981981555040418",
    },
    {
        title: "Fateful Hour",
        author: "ABZB, The Count of Real Pokemon",
        text: "At fox-box  in this fateful hour,\n" +
            "I call on Bobbles with their mod-power,\n" +
            "And  Lee with his bondings,\n" +
            "And Zinnia with her dragons,\n" +
            "And the Ninetales-fire with all the strength she hath,\n" +
            "And Grovyle with his rapid wrath,\n" +
            "And Corvisquire with swiftness along his path,\n" +
            "And Octillery in his deepness,\n" +
            "And Shinx with her cuteness,\n" +
            "And the Sixth with their hiddenness:\n" +
            "All these I place,\n" +
            "By Arceus's almighty help and Fugg's own grace\n" +
            "Between myself and the powers of darkness!",
        link: null,
        image: null,
        audio: null,
        video: null,
        pdf: null,
        caption: null,
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1095108839130529903",
    },
    {
        title: "Box 3",
        author: "Arcaryx",
        text: null,
        link: null,
        image: "/static/pins/fox-box-ai.png",
        audio: null,
        video: null,
        pdf: null,
        caption: null,
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1097749108204240949",
    },
    {
        title: "Draw me",
        author: "egoo",
        text: null,
        link: null,
        image: "/static/pins/9t-draw-me.png",
        audio: null,
        video: null,
        pdf: null,
        caption: "Been thinking about Nine deciding to troll Lee mid-taking notes ang going \"Draw me like one of your French girls\" on him, for a while now. \n" +
            "No idea when/if ever gon finish, so have sketch until then",
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1101961086506377230",
    },
    {
        title: "Evolved",
        author: "ShaRose",
        text: null,
        link: null,
        image: "/static/pins/evolved.png",
        audio: null,
        video: null,
        pdf: null,
        caption: "quicky I had an idea for",
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1107396535039295620",
    },
    {
        title: "Plushie",
        author: "emeraldleafeon",
        text: null,
        link: null,
        image: "/static/pins/vulpix-plushie.jpg",
        audio: null,
        video: null,
        pdf: null,
        caption: null,
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1111845558156533790",
    },
    {
        title: "Doubts of Romantic Viability Persist",
        author: "egoo",
        text: null,
        link: null,
        image: "/static/pins/doubts.png",
        audio: null,
        video: null,
        pdf: null,
        caption: "Extremely low effort because late and I can't be arsed",
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1116837567657164871",
    },
    {
        title: "Things will never be the same",
        author: "ShaRose",
        text: null,
        link: null,
        image: null,
        audio: null,
        video: "/static/pins/things-will-never-be-the-same.mp4",
        pdf: null,
        caption: "I don't know why this wasn't posted here but",
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1117133882236612618",
    },
    {
        title: "This Is Egoo's Fault",
        author: "egoo",
        text: null,
        link: null,
        image: "/static/pins/this-is-egoos-fault.jpg",
        audio: null,
        video: null,
        pdf: null,
        caption: "Imma repost on sb later today too. All shall suffer :deeveeous:",
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1117730124561916037",
    },
    {
        title: "The Hensons",
        author: "egoo",
        text: null,
        link: null,
        image: "/static/pins/the-hensons.png",
        audio: null,
        video: null,
        pdf: null,
        caption: "Behold. My autism",
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1119322736708309082",
    },
    {
        title: "Sleepy Time",
        author: "Murtaugh",
        text: null,
        link: null,
        image: "/static/pins/sleepy-time.png",
        audio: null,
        video: null,
        pdf: null,
        caption: "It was a very busy day at work. By the time you get home, all you want to do is sleep. Fortunately, your beloved companion seems to know exactly what you need as soon as you step through the door. She's been by your side since you were a child, and on this night, she's the best blanket in the world...",
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1128899217704898560",
    },
    {
        title: "Borgar",
        author: "Moxie",
        text: null,
        link: null,
        image: "/static/pins/9t-shower-and-a-borgar.png",
        audio: null,
        video: null,
        pdf: null,
        caption: "Thank @Forgothrax, Debased Espeon Lover's comment in the patron thread for this one.",
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1139316475384045738",
    },
    {
        title: "Intended",
        author: "Shedowtnt",
        text: "Own a Ninetales for self defense, since thats what the Creation Trio intended\n" +
            "Four Rocket grunts break into the my house\n" +
            "\"What in Distortion\" As i grabbed my trainer cap and release my Ninetales.\n" +
            "Blow a golf sized hole throught the first grunt with a flamethrower, he is dead on the spot\n" +
            "Release a Magnemite and have it use Zap cannon on the second grunt, miss him entirely because it has only 50% acuracy and nail the neighbour's Vaporeon\n" +
            "I have to resort to the Octillery at the top of the stairs loaded with a Octazooka\n" +
            "\"Tally ho lads\" The octazooka shreds two grunts in the blast, the sound and extra shrapnel set off car alarms.\n" +
            "Release Sceptile and have him charge the last terrified pokemon thief with Leaf Blade.\n" +
            "He bleeds out waiting on the Jannies to arrive since triangular Leaf Blade wounds are impossible to stitch up. \n" +
            "Just as the Creation Trio intended.",
        link: null,
        image: null,
        audio: null,
        video: null,
        pdf: null,
        caption: null,
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1150994650795098172",
    },
    {
        title: "Libido",
        author: "Fuggmann",
        text: "Are we really doing this? Here.\n" +
            "\n" +
            "Umbreon - Easiest. Dark types are in tune with their wants and needs. Won't be shy about propositioning.\n" +
            "Jolteon - Gotta ovulate fast. Their sped up bodily processes apply to reproduction as well. \n" +
            "Leafeon - Being able to subsist as both a mammal and a plant means abundant resources for kits virtually everywhere but artic areas. They breed year-round and a warm, sunny day is enough to get them in the mood.\n" +
            "Vaporeon - Varies, but usually somewhere in the middle. Opportunistic ones who dwell by deltas to take advantage of both river and ocean prey breed more often, as do ones living in especially clean water.\n" +
            "Espeon - Despite possessing all the tools needed to be successful virtually anywhere, Espeon wisely does not take breeding lightly. They need to confirm their environment is stable before they feel comfortable doing the deed.\n" +
            "Flareon - Often living in harsher, warmer areas means they're conditioned to try for kits only when conditions look good. Despite being fire types, they take their sweet time getting warmed up.\n" +
            "Glaceon - With slow metabolisms and the hardest environment for young to live in, they have the lowest sex drive of the eeveelutions. \n" +
            "Sylveon - Wild card. Cannot be predicted with any accuracy. Could be higher than Umbreon, could be lower than Glaceon.",
        link: null,
        image: null,
        audio: null,
        video: null,
        pdf: null,
        caption: null,
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1212169834960396308",
    },
    {
        title: "Enlightenment",
        author: "LifeAndLemmons 🍋",
        text: null,
        link: null,
        image: "/static/pins/The_Enlightenment_of_egoo.png",
        audio: null,
        video: null,
        pdf: null,
        caption: "it is done.\n" +
            "i call it, The Enlightenment of @egoo0676",
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1220351792689451028",
    },
    {
        title: "Giggling",
        author: "Fuggmann",
        text: null,
        link: null,
        image: null,
        audio: null,
        video: null,
        pdf: null,
        caption: "\"True, it is plain at first glance. There is no knot, or a flare, or any thing else, but if one reads between the lines? They'll find that nature itself is assured of a man's virility, for he requires no special tricks to do a job well done.\" \n" +
            "\"Now, enough waxing poetic about genitals of all things. We aren't a bunch of blushing, giggling kits, are we?\"\n" +
            "-Ninetales",
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1262473629581574225",
    },
    {
        title: "Short Tales",
        author: "Vetu Asaber",
        text: null,
        link: null,
        image: "/static/pins/short-tales.png",
        audio: null,
        video: null,
        pdf: null,
        caption: "9T is still a Vulpix deep down, eh?",
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1284895062286798869",
    },
    {
        title: "Safety Measures",
        author: "Vetu Asaber",
        text: null,
        link: null,
        image: "/static/pins/safety_measures_borne_of_caution.png",
        audio: null,
        video: null,
        pdf: null,
        caption: "I'm not sure if this should go in the other channel, but I pictured that Lee, being the cautious man that he is, would 100% send a decoy during anything too dangerous.",
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1285060111869411361",
    },
    {
        title: "Fabulous",
        author: "Vetu Asaber",
        text: null,
        link: null,
        image: "/static/pins/9t-fabulous.png",
        audio: null,
        video: null,
        pdf: null,
        caption: "My good sir, you have a knack for finding exquisite foxos.",
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1285270328162914377",
    },
    {
        title: "Not Again",
        author: "Vetu Asaber",
        text: null,
        link: null,
        image: "/static/pins/not_again.png",
        audio: null,
        video: null,
        pdf: null,
        caption: null,
        discordLink: "https://discord.com/channels/885617127032225932/1025603039635198022/1300705322973003778",
    },
];

const _getAllPinSlugs = function() {
    return all_pins.map((item) => {
        return hash.sha256().update(item.discordLink).digest('hex');
    })
}

export const getAllPinSlugs = memoize(_getAllPinSlugs)

export const _getAllPinsMap = function() {
    const pinsMap = {};
    all_pins.forEach((item) => {
        const hashed = hash.sha256().update(item.discordLink).digest('hex');
        pinsMap[hashed] = item;
    })
    return pinsMap;
}

export const getAllPinsMap = memoize(_getAllPinsMap)
