import {getAllPinsMap, getAllPinSlugs} from "../data/pin-definitions";
import {Grid} from "@mui/material";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ImageIcon from '@mui/icons-material/Image';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import LinkIcon from '@mui/icons-material/Link';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';


export default function PinsList() {
  return (
    <div className="App" id="pins">
        <header className="App-header">
            <Grid container
                  direction="row"
                  spacing={2}
                  justifyContent="space-around"
                  alignItems="center"
            >
            {getAllPinSlugs().map((pin_slug, i) => {
                const pin_object = getAllPinsMap()[pin_slug];
                return (
                    <Grid item xs={3}>
                        <a className="App-link" href={`/pins/${pin_slug}`} key={i}>
                            {pin_object.title}
                        </a>
                        &nbsp;
                        {
                            pin_object.text || pin_object.caption ? <TextSnippetIcon color="primary"/> : <></>
                        }
                        {
                            pin_object.pdf ? <PictureAsPdfIcon color="primary"/> : <></>
                        }
                        {
                            pin_object.image ? <ImageIcon color="primary"/> : <></>
                        }
                        {
                            pin_object.audio ? <AudiotrackIcon color="primary"/> : <></>
                        }
                        {
                            pin_object.video ? <OndemandVideoIcon color="primary"/> : <></>
                        }
                        {
                            pin_object.link ? <LinkIcon color="primary"/> : <></>
                        }
                    </Grid>
                )
            })}
            </Grid>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <a href="/">Back</a>
        </header>
    </div>
  );
}
