import React, { useState } from "react";
import { Document, Page } from "react-pdf";

export default function AllPages(props) {
    const [numPages, setNumPages] = useState(null);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const { pdf } = props;

    return (
        <Document className={"PDFDocument"}
            file={pdf}
            options={{}}
            onLoadSuccess={onDocumentLoadSuccess}
        >
            {Array.from(new Array(numPages), (el, index) => (
                <Page className={"PDFPage"} key={`page_${index + 1}`} pageNumber={index + 1} width={1000} renderTextLayer={false} renderAnnotationLayer={false}/>
            ))}
        </Document>
    );
}