import {all_sacred_texts} from "../data/sacred-texts-definitions";
import { useLoaderData } from "react-router-dom";
import {Typography} from "@mui/material";

export async function loader({ params }) {
    return all_sacred_texts[params.sacredTextSlug];
}

export default function SacredText() {
    const sacredText = useLoaderData();

    if(sacredText.image){
        return (
            <div className="App" id="sacred-texts">
                <header className="App-header">
                    <a href={sacredText?.image} target="_blank" rel="noreferrer"><img className="responsiveImg" alt={sacredText?.title} src={sacredText?.image}/></a>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <a href="/sacred-texts">Back</a>
                </header>
            </div>
        );
    }else if(sacredText.audio){
        return (
            <div className="App" id="sacred-texts">
                <header className="App-header">
                    <Typography>{sacredText?.title}</Typography>
                    <audio controls>
                        <source src={sacredText?.audio} type="audio/mpeg"/>
                        Your browser does not support the audio element.
                    </audio>
                    {
                        sacredText.audioTranscription ?
                            <blockquote className="display-line-break">{sacredText.audioTranscription}</blockquote>
                        : <></>
                    }
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <a href="/sacred-texts">Back</a>
                </header>
            </div>
        )
    }else{
        return (
            <div className="App" id="sacred-texts">
                <header className="App-header">
                    <span>Error</span>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <a href="/sacred-texts">Back</a>
                </header>
            </div>
        )
    }
}
